import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";
import Requestform from "./Requestform";

const Vaccrm = () => {
  return (
    <div>
      <Header />
      <div className="crm-banner">
        <img src="assets/img/crm/crm-banner1.png" alt="cybersecurity"></img>

        <div className="crm-header-left">
          <h1 id="CRMSOFTWARE">
            THESIS<span style={{ color: "#35bfff" }}>CIRCLE</span>
          </h1>
          <h1>CRM SOFTWARE</h1>
          {/* <p>
            for Small and Medium Businesses Build a Scalable sales process and
            grow yourbusiness faster
          </p> */}
        </div>
      </div>

      {/* ----------------------------------------CUSTOMER-ReLATIONSHIP------------------------------ */}
      <div className="container  CUSTOMER-ReLATIONSHIP">
        <h5>BE ON TOP OF YOUR CUSTOMER RELATIONSHIP WITH THESISCRICLE CRM</h5>
        <p>WORLDS BEST CRM?</p>
      </div>
      {/* -------------------------------------------THESISCRICLE-CRM------------------------------ */}
      <div className=" mt-2  thesis-crm">
        <div className="thesis-crm-left">
          <h1>WHY CHOOSE THESISCRICLE CRM</h1>
          <h5>
            Thesiscricle crm software has been helping organization to automate
            organize and imporve their business process
          </h5>
          <p>
            Our crm creates visibility and increases productivity our crm
            software can be used by all business including real estate. Eduction
            institudes, Healthcare,Banking and finical service,Automobile
            industry, Travel Agency etc
          </p>
        </div>
        <div className="thesis-crm-right">
          <Requestform />
        </div>
      </div>
      {/* -------------------------------------------CRM-SOFTWARE------------------------------ */}
      <div className=" mt-2 CRM-SOFTWARE py-5">
        <div className="CRM-SOFTWARE-text">
          <h1>HOW CRM SOFTWARE HELPS YOUR BUSINESS</h1>
          <p>
            Customer Relationship Management System is used to improve
            Relationship with customer,sign up new customer and to manage former
            Customer
          </p>
        </div>
        <div className="CRM-SOFTWARE-content ">
          <div>
            <img src="assets/img/crm/icon1.png" alt="img"></img>
            <p>Zero Lead Leakage</p>
          </div>
          <div>
            <img src="assets/img/crm/icon2.png" alt="img"></img>
            <p>Automated Email&SMS Follow UP</p>
          </div>
          <div>
            <img src="assets/img/crm/icon3.png" alt="img"></img>
            <p>Closed more Sales</p>
          </div>
          <div>
            <img src="assets/img/crm/icon4.png" alt="img"></img>
            <p>Improve Customer Relationship</p>
          </div>
        </div>
      </div>
      {/* -------------------------------------------degree-lead------------------------------ */}
      <div className="degree-lead">
        <div className="degree-lead-left">
          <img src="assets/img/crm/360.png" alt="img"></img>
        </div>
        <div className="degree-lead-right">
          <h1>360 degree Lead Management</h1>
          <ul>
            <li>
              Our CRM help’s businesses track and manage leads throughout the
              sales pipeline.
            </li>
            <li>
              Leads can be categorized, assigned to specific sales
              representatives
            </li>
            <li>
              Leads can be integrated with social media platforms like google
              ads, face book, India Mart etc. to get the leads directly to CR
            </li>
            <li>Record all incoming call and log all outgoing leads.</li>
            <li>
              Manage all meeting schedule. CRM can automatically remind the
              sales person for this meeting.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Vaccrm;
