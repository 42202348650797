import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";

const Corporatevideo = () => {
  return (
    <div>
      <Header />
      <section className="corporate-video-content" style={{ minHeight: "100vh" }}>
        <Container style={{ marginTop: "100px" }}>
          <Row className="justify-content-center align-items-center">
            <Col md={6}>
              <h2 style={{ color: "#012970", fontSize: "48px", fontWeight: "800", fontFamily: "Nunito, sans-serif", textAlign: "center" }}>
                Cyber Security
              </h2>
              <p>
                In today's interconnected digital landscape, cybersecurity awareness has become paramount. Every click, every download, and every login presents a potential risk. It's crucial for individuals and organizations alike to stay vigilant and informed about the latest threats lurking in cyberspace. From phishing emails to sophisticated malware attacks, the cyber threat landscape is constantly evolving. By practicing good cyber hygiene, such as maintaining strong passwords, being cautious of suspicious links, and keeping software up to date, individuals can significantly reduce their risk of falling victim to cyber attacks. Moreover, fostering a culture of cybersecurity awareness within organizations through regular training and education ensures that everyone plays a role in defending against cyber threats. Ultimately, in the battle against cybercrime, awareness is our greatest defense.
              </p>
            </Col>
            <Col md={6}>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/VEQd-jmVs44?si=b4e6vdsKl4O_bejV"
                  title="Corporate Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default Corporatevideo;
