import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Card } from "react-bootstrap";

const Vaccallcenter = () => {
  return (
    <div>
      <Header />
      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1">
              <h1>VAC Call Center Software</h1>
              <br></br>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>VAC call center solution</b> is a comprehensive software to
                facilitate effective communication between customers and
                businesses. It serves as a centralized hub where incoming and
                outgoing calls are managed, routed, and monitored efficiently.
                These solutions are crucial for businesses across various
                industries, ranging from customer support to sales and technical
                assistance.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start ">
                  <a
                    href="#about"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    style={{ borderRadius: "50px" }}
                  >
                    <span style={{ padding: "10px 5px", borderRadius: "50px" }}>
                      Find Out More
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img order-1 order-lg-2"
              data-aos-delay="200"
            >
              <img
                src="assets/img/vac.avif"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="features">
        <div className="container">
          <div className="row">
            <h1 style={{ textAlign: "center", paddingBottom: "50px" }}>
              Key features and benefits of VAC Call Center software
            </h1>
            <br></br>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>Interactive Voice Response </h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    VAC software allows callers to interact with an automated
                    voice system to navigate through various options that
                    company provides and select the appropriate department or
                    service. Our IVR can handle basic inquiries, collect
                    information from callers, and route them to the appropriate
                    agent or department without the need for human intervention.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>Why should you opt for it?</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    We ensure you get alerts of possible quality issues, crashes
                    or bugs well in advance to solve them before it affects the
                    end user. Thesis Circle guarantees consistent quality and
                    on-time services with timely checks and ensuring everything
                    is up to date.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>Automatic Call Distribution </h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    Our dialer software routes incoming calls automatically to
                    the most appropriate agent or department based on predefined
                    criteria such as IVR selection, Agent skill set or agent
                    availability. This ensures that customers are quickly
                    connected to the right person to address their inquiries.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>Call queue </h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    When all agents are busy attending to other calls, other
                    incoming calls are placed in a queue. VAC software can also
                    give details of callers in queue. We can also provide
                    callers in queue a voice message indicating estimated wait
                    time or offer the option to receive a call back instead of
                    waiting on hold for long time.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>Music on Hold </h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    You can play your audio advertisement or offer messages or
                    any upcoming events which customer can hear while on hold.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>Call Monitoring</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    Helps team leads to monitor live calls to ensure quality
                    standards and provide, if necessary, a real-time coaching to
                    agents
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>Remote Dialer App</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    Agent can receive calls on their respective mobile phone.
                    Once the call is completed they can check the call details
                    in the app and update status and comments.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>Call Recording</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    VAC dialler offers quality call recording. Businesses
                    require this to review past interactions for training
                    purposes, compliance, or dispute resolution. Our software
                    search option as well.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>CRM Integration</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    Our software allows a pop up on the display screen which
                    gives agents access to customer information, previous
                    interactions, and purchase history during calls. This
                    enables personalized and efficient customer service, as
                    agents can quickly address inquiries and provide relevant
                    suggestions.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>EPABX Integration</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    You can retain their existing conventional EPABX and still
                    use our call center solution by interconnecting these two
                    devices thru PRI or SIP for seamless communication.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="custom-card" style={{ height: "300px" }}>
                <Card.Header>
                  <h5>Reports</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    We provide details of call volume, agent performance,
                    customer satisfaction metrics, and other key performance
                    indicators. These reports help managers make informed
                    decisions to optimize operations and improve customer
                    service.
                  </p>
                </Card.Body>
              </Card>
            </div>
          </div>
          <p>
            In summary, VAC call center solution streamlines communication
            between businesses and customers, enhances operational efficiency,
            and improves customer satisfaction. By leveraging advanced features
            and technologies, businesses can deliver exceptional customer
            service and gain a competitive edge in today's mark.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Vaccallcenter;
