import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header
        id="header"
        className="header fixed-top"
        style={{ height: "80px", backgroundColor: "#F3FBF7" }}
      >
        <div
          className="container-fluid container-xl d-flex align-items-center justify-content-between"
          style={{ height: "80px" }}
        >
          <Link to="/" className="logo d-flex align-items-center">
            <img
              src="assets/img/logo1.png"
              alt=""
              style={{ height: "100%", width: "250px", objectFit: "cover" }}
            />
          </Link>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link to="/" className="nav-link scrollto active">
                  Home
                </Link>
              </li>

              <li className="dropdown">
                <Link to="#">
                  <span>About </span> <i className="bi bi-chevron-down"></i>
                </Link>
                <ul>
                  <li>
                    <Link to="/Whowe">Who We Are </Link>
                  </li>
                  <li>
                    <Link to="/Clients">Clients</Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <Link to="#">
                  <span>Business Application </span>{" "}
                  <i className="bi bi-chevron-down"></i>
                </Link>
                <ul>
                  <li>
                    <Link to="/Whatsappapi">Whatsapp API </Link>
                  </li>
                  <li>
                    <Link to="/Callcenterdailer">Call Centre Dialer</Link>
                  </li>
                  <li>
                    <Link to="/Vaccallcenter">VAC Call Center Software</Link>
                  </li>
                  <li>
                    <Link to="/Vaccrm">
                      VAC Customer Relationship Management <br></br> (CRM)
                      software
                    </Link>
                  </li>
                  <li>
                    <Link to="/Ivr">IVR</Link>
                  </li>
                  <li>
                    <Link to="/Ippbx">IPPBX</Link>
                  </li>
                  <li>
                    <Link to="/Erp">ERP</Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <Link to="#">
                  <span>Service </span> <i className="bi bi-chevron-down"></i>
                </Link>
                <ul>
                  <li className="dropdown">
                    <Link to="#">
                      <span>Cyber Security</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/Cybersecurityservice">
                          Cyber Security Service{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Cybersecurityproducts">
                          Cyber Security Products
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <Link to="#">
                      <span>Core Modernization</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/Applicationmaintennce">
                          Application Management &<br></br>
                          Workflow System
                        </Link>
                      </li>
                      <li>
                        <Link to="/Applicationmodernization">
                          Application Modernization
                        </Link>
                      </li>
                      <li>
                        <Link to="/Productengineering">
                          Product Engineering
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/ITconsulting">IT Consulting & Service</Link>
                  </li>
                  {/* <li>
                    <Link to="/Businessapp">Business Application</Link>
                  </li> */}
                  <li className="dropdown">
                    <Link to="#">
                      <span>Digital Experience</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/Intelligence">Digital Marketing</Link>
                      </li>
                      <li>
                        <Link to="/Corporatevideo">Corporate video</Link>
                      </li>
                      <li>
                        <Link to="/Animation">2D Animation</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <Link className="nav-link scrollto" to="/Service">
                  Resources
                </Link>
              </li>
              <li>
                <Link className="getstarted scrollto" to="/ContactUs">
                  Contact Us
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
