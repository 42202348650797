import React, { useEffect } from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";

const Whowe = () => {
  useEffect(() => {
    document.title = "About Us | Thesis Circle";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "A world of talent at your fingertips we are dedicated to providing top-notch It solutions and services to help businesses "
      );
  }, []);
  return (
    <div>
      <Header />
      {/* <section id="about" className="about mt-5">
        <div className="container">
          <div className="row gx-0">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <div className="content text-justify">
                <h2>Welcome to Thesis Circle!</h2>
                <h1>
                  <b>A world of talent at your fingertips</b>
                </h1>
                <p>
                  At Thesis Circle, we are dedicated to providing top-notch IT
                  solutions and services to help businesses thrive in the
                  digital age. We understand the ever-evolving landscape of
                  technology and the challenges faced by organizations in
                  harnessing its full potential. That's why we are here to
                  simplify your IT journey and empower you to achieve your
                  business goals.
                  <br></br>
                  <br></br>
                  <b>Our Team:</b> At the heart of our success is our team of
                  highly skilled and passionate professionals. Our diverse group
                  of experts brings together a wealth of knowledge and
                  experience in various domains of IT. From software development
                  to cybersecurity, cloud solutions to data analytics, our team
                  has the expertise to deliver comprehensive and innovative
                  solutions tailored to your specific needs.
                  <br></br>
                  <br></br> <b>Our Approach:</b> We believe that every business
                  is unique, and that's why we take a personalized approach to
                  every project we undertake. Our team collaborates closely with
                  you to gain a deep understanding of your objectives,
                  challenges, and aspirations. We then craft customized IT
                  strategies that align with your goals, optimize your
                  operations, and drive growth.
                </p>
                <div className="text-center text-lg-start">
                  <Link
                    to="#"
                    className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <img src="assets/img/about.jpg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section> */}
      <div className="about-header">
        <div className="about-top container">
          <img src="assets/img/about/about-logo.png"></img>
          <h1>About Thesis Circle</h1>
        </div>
        <div className="about-main container">
          <div className="about-left ">
            <div className="about-left-text">
              <img src="assets/img/about/about-icon3.png"></img>
              <p>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  Who We Are:
                </span>
                 A Network Of Intellectuals - Your Partner for Digital Success
              </p>
            </div>
            <div className="about-left-text">
              <img src="assets/img/about/about-icon4.png"></img>

              <p>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  Our Vision:
                </span>
                 Simplifying IT for Success in the Digital Age for both
                Corporates & Educational Institutions
              </p>
            </div>
            <div className="about-left-text">
              <img src="assets/img/about/about-icon5.png"></img>

              <p>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  Comprehensive Solutions:
                </span>
                 Partnered with Industry Professionals for Your Success
              </p>
            </div>
          </div>
          <div className="about-right">
            <h3>Our Expertise:</h3>
            <hr></hr>
            <p>Expert Software Development</p>
            <hr></hr>
            <p>Engaging Workshops, Training & Placement</p>
            <hr></hr>
            <p>Cutting-edge Solutions for Your Needs</p>
            <hr></hr>
            <h3>Why Choose Us:</h3>
            <hr></hr>
            <p>Passionate Blended Team of IT & Academic Professionals</p>
            <hr></hr>
            <p>Tailored Solutions Just for You</p>
            <hr></hr>
            <p>To Be Your One-Stop Solution Provider</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Whowe;
