import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer
      id="footer"
      className="footer"
      style={{ backgroundColor: "rgb(255 255 255)" }}
    >
      <Container>
        <div className="footer-top">
          <Row>
            <Col lg={3} md={12} className="footer-info">
              <Link to="/" className="logo d-flex align-items-center">
                <img
                  src="assets/img/logo1.png"
                  alt=""
                  style={{
                    height: "100%",
                    width: "250px",
                    objectFit: "cover",
                  }}
                />
              </Link>
              <p>
                We handle factors IT Consulting & Technology boosts business
                success. <br></br>We Offer a Wide Range of Technological
                Services.
              </p>
              <div className="social-links mt-3">
                <Link to="#" className="twitter">
                  <i className="bi bi-twitter"></i>
                </Link>
                <Link to="#" className="facebook">
                  <i className="bi bi-facebook"></i>
                </Link>
                <Link to="#" className="instagram">
                  <i className="bi bi-instagram"></i>
                </Link>
                <Link to="#" className="linkedin">
                  <i className="bi bi-linkedin"></i>
                </Link>
              </div>
            </Col>
            <Col lg={2} md={6} className="footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Whowe">Who We Are</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Clients">Clients</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Service">Resources</Link>
                </li>
              </ul>
            </Col>
            <Col lg={2} md={6} className="footer-links">
              <h4>Business Application</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Whatsappapi">Whatsapp API</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Callcenterdailer">Call Centre Dialer</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Vaccallcenter">VAC Call Center Software</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Vaccrm">VAC Customer Relationship Management</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Ivr">IVR</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Ippbx">IPPBX</Link>
                </li>
              </ul>
            </Col>
            <Col lg={2} md={6} className="footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Cybersecurityservice">Cyber Security Service</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="#">Cyber Security Products</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Applicationmaintennce">
                    Application Management
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Applicationmodernization">
                    Application Modernization
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Productengineering">Product Engineering</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/ITconsulting">IT Consulting & Service</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Intelligence">Digital Marketing</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Corporatevideo">Corporate video</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Animation">2D Animation</Link>
                </li>
              </ul>
            </Col>
            <Col
              lg={3}
              md={12}
              className="footer-contact text-center text-md-start"
            >
              <h4>Contact Us</h4>
              <p>
                Bengaluru <br></br>
                Karnataka, India
                <br></br>
                <strong>Phone:</strong>{" "}
                <Link to="tel:+91 6362366840">+91 6362366840</Link>
                <br></br>
                <strong>Email:</strong>{" "}
                <Link to="mailto:sales@thesiscircle.com">
                  sales@thesiscircle.com
                </Link>
                <br></br>
              </p>
            </Col>
          </Row>
        </div>

        <div className="footer-bottom text-center">
          <p>
            &copy; Copyright{" "}
            <strong>
              <span>ThesisCircle</span>
            </strong>
            . All Rights Reserved
          </p>
          <p className="credits">
            Designed by <Link to="/">CirclePlus</Link>
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
