import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";

const Businessapp = () => {
  return (
    <div>
      <Header />
      <section id="services" className="services">
        <div className="container">
          <header className="section-header">
            <p style={{ fontSize: "50px", lineHeight: "60px" }}>
              Your Enterprise Excellence Begins Here!
            </p>
            <br></br>
            <h5>Products & Platforms Driving Business Transformation</h5>
            <br></br>
            <h3 style={{ color: "hsl(249  100%  61%)", fontWeight: "800" }}>
              Whatsapp API | Call Centre Dialer | IVR | IPPBX
            </h3>
          </header>
          <section
            id="hero"
            className="hero d-flex align-items-center"
            style={{ backgroundColor: "hsl(240 45% 98%)" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <h3 style={{ color: "#012970"  }}>
                    <span style={{ color: "rgb(124, 65, 251)" ,fontWeight:"800"}}>
                      Thesis Circle Comprehensive portfolio{" "}
                    </span>{" "}
                    enabling organizations embrace digital transformation by
                    providing innovative enterprise solutions
                  </h3>
                  <ul>
                    <li style={{ color: "#012970", fontWeight: "500" }}>
                      Whatsapp Business API{" "}
                    </li>
                    <p>
                      Personalize communication and sell more with the WhatsApp
                      Business API platform that automates marketing, sales,
                      service and support.
                    </p>
                    <li style={{ color: "#012970", fontWeight: "500" }}>
                      Call Centre Dialer
                    </li>
                    <p>
                      Innovative call centre software helps in managing inbound,
                      outbound and support calls
                    </p>
                    <li style={{ color: "#012970", fontWeight: "500" }}>IVR</li>
                    <p>
                      At Interactive Voice Solutions, we specialize in providing
                      cutting-edge Interactive Voice Response (IVR) systems
                      tailored to meet the diverse needs of businesses across
                      industries
                    </p>
                    <li style={{ color: "#012970", fontWeight: "500" }}>
                      IPPBX
                    </li>
                    <p>
                      Our IPPBX is based on asterisk platform, its easy to
                      install and configure your business-class telephony system
                    </p>
                  </ul>
                </div>
                <div className="col-lg-6 hero-img" data-aos-delay="200">
                  <img
                    src="assets/img/application.jpeg"
                    style={{ borderRadius: "20px" }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section id="features" class="features">

      <div class="container" >

        

        <div class="row">

          <div class="col-lg-6">
            <img src="assets/img/features.png" class="img-fluid" alt=""/>
          </div>

          <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
            <div class="row align-self-center gy-4">

              <div class="col-md-6" data-aos-delay="200">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>IT</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos-delay="300">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Education</h3>
                </div>
              </div>

              <div class="col-md-6"  data-aos-delay="400">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Retail</h3>
                </div>
              </div>

              <div class="col-md-6"  data-aos-delay="500">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Staffing</h3>
                </div>
              </div>

              <div class="col-md-6"  data-aos-delay="600">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Facility Management</h3>
                </div>
              </div>

              <div class="col-md-6"  data-aos-delay="700">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Manufacturing</h3>
                </div>
                
              </div>
              <p style={{ color: "hsl(249  100%  61%)", fontWeight: "500" }}>
                  CONNECTING EVERY NOOK OF YOUR BUSINESS
                </p>
            </div>
          </div>
        </div> 
      </div>

    </section>

      
      
      

    

      <Footer />
    </div>
  );
};

export default Businessapp;
