import React, { useEffect } from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";
// import Requestform from "../Businessapplication/Requestform";

const Home = () => {
  useEffect(() => {
    document.title = "Home | Thesis Circle";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "Great IT field experience Innovative IT solutions to help your business"
      );
  }, []);

  return (
    <div>
      <Header />

      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <section
              className="heroslider d-flex align-items-center"
              style={{ backgroundImage: "url('assets/img/bg2.jpg')" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6"></div>

                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center"
                    // style={{
                    //   backgroundColor: "blue",
                    //   height: "500px",
                    //   borderRadius: "20px",
                    //   color: "white",
                    // }}
                  >
                    <h3
                      style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}
                    >
                      Great IT field experience
                    </h3>
                    <h1 style={{ color: "white" }}>
                      Innovative IT solutions<br></br> to help your business
                    </h1>
                    <br></br>
                    <h4 data-aos-delay="400" style={{ color: "whitesmoke" }}>
                      Our Number GoalIs Become The Most Ruptable WorldWide
                      Provider of IT Solution{" "}
                    </h4>{" "}
                    {/* <div data-aos-delay="600">
                      <div className="text-center text-lg-start">
                        <Link
                          to="/ContactUs"
                          className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        >
                          <span>Let's Talk Now</span>
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                    {/* <Requestform /> */}
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="carousel-item">
            <section
              className="heroslider d-flex align-items-center"
              style={{ backgroundImage: "url('assets/img/bg3.jpg')" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6"></div>

                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <h3
                      style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}
                    >
                      Simple Integration
                    </h3>
                    <h1 style={{ color: "white" }}>
                      Best IT Support for business success.
                    </h1>
                    <br></br>
                    <h4 data-aos-delay="400" style={{ color: "whitesmoke" }}>
                      Our Number GoalIs Become The Most Ruptable WorldWide
                      Provider of IT Solution{" "}
                    </h4>
                    <div data-aos-delay="600">
                      <div className="text-center text-lg-start">
                        <Link
                          to="/ContactUs"
                          className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        >
                          <span>Let's Talk Now</span>
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="carousel-item">
            <section
              className="heroslider d-flex align-items-center"
              style={{ backgroundImage: "url('assets/img/bg6.jpg  ')" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6"></div>

                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <h3
                      style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}
                    >
                      Great IT field experience
                    </h3>
                    <h1 style={{ color: "white" }}>
                      Innovative IT solutions<br></br> to help your business
                    </h1>
                    <br></br>
                    <h4 data-aos-delay="400" style={{ color: "whitesmoke" }}>
                      Our Number GoalIs Become The Most Ruptable WorldWide
                      Provider of IT Solution{" "}
                    </h4>
                    <div data-aos-delay="600">
                      <div className="text-center text-lg-start">
                        <Link
                          to="/ContactUs"
                          className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        >
                          <span>Let's Talk Now</span>
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden" style={{ color: "black" }}>
            Previous
          </span>
        </button>

        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <section id="services" className="services">
        <div className="container">
          <header className="section-header">
            <h3 style={{ color: "hsl(249  100%  61%)", fontWeight: "800" }}>
              Professional IT services for your business
            </h3>
            <p className="heading">
              We provide a wide range of IT services <br></br>that ensure your
              company's success.
            </p>
            <br></br>
            <h5>
              We are committed to offering IT services to small and medium-sized
              <br></br> businesses with enterprise-level scalability and
              efficiency.
            </h5>
          </header>

          <div
            className="row gy-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-4 col-md-6" data-aos-delay="200">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Cyber Security</h3>
                <p>
                  Protect your digital assets. Stay safe from online threats
                  with our cutting-edge security solutions.
                </p>
                <a href="/Cybersecurity" className="read-more">
                  <span>Discover Now</span>{" "}
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="300">
              <div className="service-box orange">
                <i className="ri-discuss-line icon"></i>
                <h3>IT Consulting & Service</h3>
                <p>
                  Maximize your IT potential. Unlock growth opportunities with
                  our expert guidance and comprehensive services.
                </p>
                <a href="/ITconsulting" className="read-more">
                  <span>Discover Now </span>{" "}
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos-delay="700">
              <div className="service-box pink">
                <i className="ri-discuss-line icon"></i>
                <h3>Business Application</h3>
                <p>
                  Streamline operations. Empower your business with powerful
                  software solutions tailored to your needs..
                </p>
                <a href="/Businessapp" className="read-more">
                  <span>Discover Now</span>{" "}
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="400">
              <div className="service-box green">
                <i className="ri-discuss-line icon"></i>
                <h3>Digital Experience</h3>
                <p>
                  Unlock insights for success. Make data-driven decisions with
                  our robust analytics and reporting tools
                </p>
                <Link to="#" className="read-more">
                  <span>Discover Now</span>{" "}
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="500">
              <div className="service-box red">
                <i className="ri-discuss-line icon"></i>
                <h3>Business Intelligence</h3>
                <p>
                  Unlock insights for success. Make data-driven decisions with
                  our robust analytics and reporting tools.
                </p>
                <a href="/Intelligence" className="read-more">
                  <span>Discover Now</span>{" "}
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="700">
              <div className="service-box pink">
                <i className="ri-discuss-line icon"></i>
                <h3>Web Development</h3>
                <p>
                  We provide the most responsive and functional IT solution to
                  enterprises and businesses all around the globe..
                </p>
                <Link to="#" className="read-more">
                  <span>Discover Now</span>{" "}
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1">
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                Boost Creativity with Reliable Tech Solutions
              </h3>
              <h1>
                In a short time span, we've made waves. Success fuels our drive.
              </h1>
              <br></br>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Thesis Circle is dedicated to providing reliable tech solutions
                that prioritize seamless system performance and responsiveness.
                We meticulously analyze and optimize every intricate detail to
                ensure a smooth user experience. By leveraging state-of-the-art
                technology, we fortify data security, protecting client
                databases and establishing robust firewalls. Our commitment to
                excellence drives us to deliver trusted IT solutions that
                empower businesses to thrive in today's digital landscape.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    href="#about"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    style={{ borderRadius: "50px" }}
                  >
                    <span style={{ padding: "10px 5px", borderRadius: "50px" }}>
                      Find Out More
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img order-1 order-lg-2"
              data-aos-delay="200"
            >
              <img
                src="assets/img/about2.png"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <div className="container">
                <div className="row gy-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgb(124, 65, 251)",
                        height: "200px",
                        borderRadius: "20px",
                        color: "hsl(0 0% 100%)",
                      }}
                    >
                      <div className="info-box align-items-center justify-content-center">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "white",
                            height: "80px",
                            width: "80px",
                            borderRadius: "40px",
                            marginLeft: "80px",
                          }}
                        >
                          <img
                            src="assets/img/icon-1.png"
                            alt="Icon"
                            className="img-fluid"
                            style={{
                              height: "auto",

                              objectFit: "none",
                            }}
                          />
                        </div>
                        <br></br>
                        <h4>Experts Problem Solvers</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgb(30, 200, 146)",
                        height: "200px",
                        borderRadius: "20px",
                        color: "hsl(0 0% 100%)",
                      }}
                    >
                      <div className="info-box">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "white",
                            height: "80px",
                            width: "80px",
                            borderRadius: "40px",
                            marginLeft: "50px",
                          }}
                        >
                          {" "}
                          <img
                            src="assets/img/icon-2.png"
                            style={{ height: "50px", objectFit: "none" }}
                          />
                        </div>
                        <br></br>
                        <h4 style={{ textAlign: "center" }}>
                          Regular Updates <br></br>& Bug fixes
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgb(255, 66, 104)",
                        height: "200px",
                        borderRadius: "20px",
                        color: "hsl(0 0% 100%)",
                      }}
                    >
                      <div className="info-box">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "white",
                            height: "80px",
                            width: "80px",
                            borderRadius: "40px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            src="assets/img/icon-3.png"
                            alt="icon"
                            style={{ height: "50px", objectFit: "none" }}
                          />
                        </div>
                        <h4 style={{ textAlign: "center", margin: "0" }}>
                          Analysis of Creative Product and Services
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgb(255, 187, 11)",
                        height: "200px",
                        borderRadius: "20px",
                        color: "hsl(0 0% 100%)",
                      }}
                    >
                      <div className="info-box">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "white",
                            height: "80px",
                            width: "80px",
                            borderRadius: "40px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <img
                            src="assets/img/icon-4.png"
                            style={{ height: "50px", objectFit: "none" }}
                            alt="icon"
                          ></img>
                        </div>
                        <h4 style={{ textAlign: "center" }}>
                          Top-Notch, Real-time Customer Service
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              style={{}}
            >
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                What Makes <b>Thesis Circle</b> Stand Out
              </h3>
              <h1>
                Digital Technology, IT Solutions, and Service Providers
                Worldwide
              </h1>
              <br></br>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Bring win-win survival techniques to the table in order to
                assure dotted proactive control. At the end of the day, moving
                ahead, there will be a new normal that has developed.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    href="#about"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    style={{ borderRadius: "50px" }}
                  >
                    <span style={{ padding: "10px 5px", borderRadius: "50px" }}>
                      Learn More
                    </span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                We provide a wide range of services to meet your needs.
              </h3>
              <br></br>
              <h1 style={{ textAlign: "justify" }}>
                Get a Free Consultation on YourUpcoming Project Here!
              </h1>
              <br></br>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                If you have a project or idea, contact or make an appointment.
                Our rep will soon respond.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <div className="text-center text-lg-start">
                    <Link
                      to="/ContactUs"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span>Let's Talk Now</span>
                      <i className="bi bi-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img order-1 order-lg-2"
              data-aos-delay="200"
            >
              <img
                src="assets/img/consult.jpg"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div> */}
          <div className="row">
            <div
              className="col-lg-6 hero-img order-1 order-lg-2"
              data-aos-delay="200"
            >
              <img
                src="assets/img/consult.jpg"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1">
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                We provide a wide range of services to meet your needs.
              </h3>
              <br />
              <h1 style={{ textAlign: "justify" }}>
                Get a Free Consultation on Your Upcoming Project Here!
              </h1>
              <br />
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                If you have a project or idea, contact or make an appointment.
                Our rep will soon respond.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <Link
                    to="/ContactUs"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Let's Talk Now</span>
                    <i className="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/features-2.png"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                Exciting New Phase In The Development Of IT Solutions
              </h3>
              <h1 style={{ textAlign: "justify" }}>
                Let's work together to turn your ideal business into a
                profitable reality..
              </h1>
              <br></br>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                We see our ideas through from the very beginning to the very
                end.We provide solutions with the aim of establishing
                trustworthy connections.Making Arrangements in Anticipation of
                Your Achievements Provide Best IT Solutions
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container values" id="values">
        <div className="row">
          <div className="col-lg-3" data-aos-delay="200">
            <div className="box">
              <img
                src="assets/img/features-3.png"
                style={{ height: "180px" }}
                className="img-fluid"
                alt=""
              />
              <h3>Top Flexibility</h3>
              <p className="text-justify">
                Our IT solutions are designed to be flexible and scalable, so
                you can easily adapt them to your changing needs.
              </p>
            </div>
          </div>

          <div className="col-lg-3 mt-4 mt-lg-0" data-aos-delay="400">
            <div className="box">
              <img
                src="assets/img/values-2.png"
                className="img-fluid text-justify"
                alt=""
              />
              <h3>Time Saving</h3>
              <p>
                Our IT solutions can help you save time and money by automating
                tasks and streamlining processes.
              </p>
            </div>
          </div>

          <div className="col-lg-3 mt-4 mt-lg-0 " data-aos-delay="600">
            <div className="box ">
              <img
                src="assets/img/values-3.png"
                className="img-fluid text-justify "
                alt=""
              />
              <h3>Management</h3>
              <p className="text-justify">
                Our IT solutions provide you with the tools you need to manage
                your IT infrastructure effectively.
              </p>
            </div>
          </div>
          <div className="col-lg-3 mt-4 mt-lg-0" data-aos-delay="600">
            <div className="box">
              <img
                src="assets/img/values-1.png"
                className="img-fluid text-justify"
                alt=""
              />
              <h3>Collaborative</h3>
              <p className="text-justify">
                Our IT solutions are designed to promote collaboration and
                communication between employees.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
