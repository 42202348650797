import "./App.css";

import Header from "./components/navbar/Header";
import Footer from "./components/navbar/Footer";
import ContactUs from "./components/pages/ContactUs";
import Home from "./components/pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Whowe from "./components/pages/Whowe";
import Service from "./components/pages/Service";
import Cybersecurityservice from "./components/Corporate/Cybersecurityservice";
import ITconsulting from "./components/Corporate/ITconsulting";
import Businessapp from "./components/Corporate/Businessapp";
import Intelligence from "./components/Corporate/Intelligence";
import Applicationmaintennce from "./components/Corporate/Applicationmaintennce";
import Applicationmodernization from "./components/Corporate/Applicationmodernization";
import Productengineering from "./components/Corporate/Productengineering";
import Animation from "./components/Corporate/Animation";
import Whatsappapi from "./components/Businessapplication/Whatsappapi";
import Callcenterdailer from "./components/Businessapplication/Callcenterdailer";
import Ippbx from "./components/Businessapplication/Ippbx";
import Ivr from "./components/Businessapplication/Ivr";
import Vaccallcenter from "./components/Businessapplication/Vaccallcenter";
import Vaccrm from "./components/Businessapplication/Vaccrm";
import Clients from "./components/pages/Clients";
import Corporatevideo from "./components/Corporate/Corporatevideo";
import Cybersecurityproducts from "./components/Corporate/Cybersecurityproducts";
import Erp from "./components/Businessapplication/Erp";
import Requestform from "./components/Businessapplication/Requestform";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Header" element={<Header />}></Route>

          <Route path="/Whowe" element={<Whowe />}></Route>
          <Route path="/Service" element={<Service />}></Route>
          <Route path="/Clients" element={<Clients />}></Route>
          <Route path="/Requestform" element={<Requestform />}></Route>
          <Route path="/ContactUs" element={<ContactUs />}></Route>
          <Route path="/Footer" element={<Footer />}></Route>

          <Route path="/Whatsappapi" element={<Whatsappapi />}></Route>
          <Route
            path="/Callcenterdailer"
            element={<Callcenterdailer />}
          ></Route>
          <Route path="/Vaccallcenter" element={<Vaccallcenter />}></Route>
          <Route path="/Vaccrm" element={<Vaccrm />}></Route>
          <Route path="/Ippbx" element={<Ippbx />}></Route>
          <Route path="/Ivr" element={<Ivr />}></Route>
          <Route path="/Erp" element={<Erp />}></Route>

          <Route
            path="/Cybersecurityservice"
            element={<Cybersecurityservice />}
          ></Route>
          <Route
            path="/Cybersecurityproducts"
            element={<Cybersecurityproducts />}
          ></Route>
          <Route path="/ITconsulting" element={<ITconsulting />}></Route>
          <Route path="/Businessapp" element={<Businessapp />}></Route>
          <Route path="/Intelligence" element={<Intelligence />}></Route>
          <Route
            path="/Applicationmaintennce"
            element={<Applicationmaintennce />}
          ></Route>
          <Route
            path="/Applicationmodernization"
            element={<Applicationmodernization />}
          ></Route>
          <Route
            path="/Productengineering"
            element={<Productengineering />}
          ></Route>
          <Route path="/Corporatevideo" element={<Corporatevideo />}></Route>

          <Route path="/Animation" element={<Animation />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
