import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import React, { useEffect } from "react";
import Isotope from "isotope-layout";
import Swiper from "swiper";
import GLightbox from "glightbox";

const Clients = () => {
  useEffect(() => {
    const initIsotope = () => {
      let portfolioContainer = document.querySelector(".portfolio-container");
      if (portfolioContainer) {
        let portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: ".portfolio-item",
          layoutMode: "fitRows",
        });

        let portfolioFilters = document.querySelectorAll(
          "#portfolio-flters li"
        );

        portfolioFilters.forEach((filter) => {
          filter.addEventListener("click", function (e) {
            e.preventDefault();
            portfolioFilters.forEach((el) => {
              el.classList.remove("filter-active");
            });
            this.classList.add("filter-active");

            portfolioIsotope.arrange({
              filter: this.getAttribute("data-filter"),
            });
          });
        });
      }
    };

    const initLightbox = () => {
      GLightbox({
        selector: ".portfokio-lightbox",
      });
    };

    const initSlider = () => {
      new Swiper(".portfolio-details-slider", {
        speed: 400,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });
    };

    // Initialize Isotope
    initIsotope();

    // Initialize Lightbox
    initLightbox();

    // Initialize Slider
    initSlider();
  }, []);
  return (
    <div>
      <Header />
      <section id="portfolio" className="portfolio">
        <div className="container mt-5">
          <header className="section-header">
            <p>Our Clients</p>
          </header>

          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                {/* <li data-filter="*" className="filter-active">
                  All
                </li> */}
                <li data-filter=".filter-ecom">E-commerce</li>
                <li data-filter=".filter-manufacturing">Manufacturing</li>
                <li data-filter=".filter-retail">Retail</li>
                <li data-filter=".filter-trade">B2B Trading</li>
                <li data-filter=".filter-data">Data Science</li>
              </ul>
            </div>
          </div>

          <div
            className="row gy-4 portfolio-container ms-lg-5"
            style={{ textAlign: "center" }}
          >
            {/* Sample portfolio items */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-ecom">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/pothys2.jpg"
                  style={{
                    height: "225px",
                    width: "225px",
                    border: "1px solid",
                  }}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-ecom">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/kalyan.jpg"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-manufacturing">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/elevate.png"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-manufacturing">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/ministerwhite.jpg"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-retail">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/pothys2.jpg"
                  style={{
                    height: "225px",
                    width: "225px",
                    border: "1px solid",
                  }}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-retail">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/kalyan.jpg"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-retail">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/elevate.png"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-trade">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/ministerwhite.jpg"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-trade">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/sdc.webp"
                  className="img-fluid"
                  style={{
                    height: "225px",
                    width: "225px",
                    border: "1px solid",
                  }}
                  alt=""
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-data">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/pothys2.jpg"
                  className="img-fluid"
                  style={{
                    height: "225px",
                    width: "225px",
                    border: "1px solid",
                  }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-data">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/kalyan.jpg"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-data">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/tecnimont.jpeg"
                  className="img-fluid"
                  style={{
                    height: "225px",
                    width: "225px",
                    border: "1px solid",
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Clients;
